import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useModal from "../Modal/useModal";
import axios from "axios";
import { API_URL } from "../constants";
import ForgotPassword from "./ForgotPassword";
import Cookies from "js-cookie";

export default function LoginForm(props) {
  const [user, setUser] = useState({ mail: "", password: "" });
  const [twoFactorCode, setTwoFactorCode] = useState(""); // Stocke le code 2FA
  const [tempToken, setTempToken] = useState(null); // Stocke le token temporaire après login
  const [rememberDevice, setRememberDevice] = useState(false); // Se souvenir de l'appareil
  const [qrCode, setQrCode] = useState(null); // QR Code 2FA
  const [secret, setSecret] = useState(null); // Secret 2FA
  const [error, setError] = useState(null); // Gestion des erreurs
  const [stayConnected, setStayConnected] = useState(false);
  const [userId, setUserId] = useState("");
  const [factorPending, setFactorPending] = useState(false);

  const navigate = useNavigate();

  const { isShowing: isForgotPasswordModalShowing, toggle: toggleForgotPasswordModal } = useModal();

  const handleForgotPassword = () => {
    toggleForgotPasswordModal();
  };

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 10000);
  }, [error]);

  const handleUserChange = (event) => setUser({ ...user, [event.target.name]: event.target.value });
  const handleCheckbox = () => setStayConnected(!stayConnected);
  const handle2FAChange = (event) => setTwoFactorCode(event.target.value);
  const handleRememberDevice = () => setRememberDevice(!rememberDevice);

  const storeUserInLocalStorage = (userData) => {
    localStorage.setItem("user", JSON.stringify(userData));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/auth/login`, user);
      const { id, require2FA, auth_token, twoFactorPending } = response.data;
      setUserId(id);
      setFactorPending(twoFactorPending);

      const stored2faToken = Cookies.get(`2fa_token_${id}`);
      if (stored2faToken) {
        completeLogin(response.data);
        return;
      }
      if (stayConnected) {
        storeUserInLocalStorage(response.data);
      }

      if (require2FA) {
        setTempToken(auth_token);
        localStorage.setItem("tempToken", auth_token);
        if (twoFactorPending) {
          await fetchQrCode(id, auth_token);
        }
      }
    } catch {
      setError("Identifiants incorrects.");
    }
  };

  const fetchQrCode = async (userId, token) => {
    try {
      const response = await axios.post(
        `${API_URL}/users/${userId}/enable-2fa`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setQrCode(response.data.qrCode);
      setSecret(response.data.secret);
    } catch {
      setError("Impossible de récupérer le QR Code.");
    }
  };

  const handleVerify2FA = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${API_URL}/users/${userId}/verify-2fa`,
        { token: twoFactorCode, rememberDevice },
        { headers: { Authorization: `Bearer ${localStorage.getItem("tempToken")}` } }
      );
      console.log(response.data);
      const {auth_token} = response.data;
      localStorage.removeItem("tempToken");
      if (rememberDevice) {
        Cookies.set(`2fa_token_${userId}`, auth_token, {
          expires: 7,
          secure: true,
          sameSite: "Strict",
        });
      }
      completeLogin(response.data);
    } catch {
      setError("Code 2FA incorrect.");
    }
  };

  const completeLogin = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
    props.setUser(data);
    navigate("/machineslist");
  };

  return (
    <div className="flex flex-col border-solid border-4 border-loginBordure rounded-2xl text-center p-12 h-auto w-11/12 sm:w-1/2">
      <h2 className="-mt-4 -mb-4 text-loginBordure font-bold text-3xl xl:text-5xl 3xl:text-6xl">Se connecter</h2>

      {!tempToken ? (
        <form className="flex flex-col w-full m-auto mt-6 " onSubmit={handleSubmit}>
          <label className="flex flex-col text-center sm:text-center sm:mb-2 sm:-mt-1 sm:text-sm xl:text-left xl:text-3xl 3xl:text-5xl mb-4">
          E-Mail
          <input
            type="mail"
            name="mail"
            placeholder="E-Mail"
            onChange={handleUserChange}
            value={user.mail}
            className="text-xs border-solid border-2 border-loginBordure rounded-2xl p-1 xl:p-5 text-center mt-2 xl:text-2xl 3xl:text-4xl"
          />
        </label>

        <label className="flex flex-col text-center sm:text-center sm:mb-2 sm:-mt-1 sm:text-sm xl:text-left xl:text-3xl 3xl:text-5xl mb-4">
          Mot de passe
          <input
            type="password"
            name="password"
            placeholder="Mot de passe"
            onChange={handleUserChange}
            value={user.password}
            className="text-xs border-solid border-2 border-loginBordure rounded-2xl p-1 xl:p-5 text-center mt-2 xl:text-2xl 3xl:text-4xl"
          />
        </label>

        <label className="block text-center text-xs sm:text-center sm:mb-2 sm:-mt-1 sm:text-base xl:text-left xl:text-3xl 3xl:text-4xl mb-6 xl:mt-2">
          Rester connecté
          <input
            type="checkbox"
            name="checkbox"
            onChange={handleCheckbox}
            checked={stayConnected}
            className="ml-4"
          />
          <span className="inline-block w-full float-none mt-10 text-blue-600 text-center sm:-mb-1 xl:text-2xl 3xl:text-4xl cursor-pointer xl:w-fit xl:float-right xl:mt-0 hover:text-yellow-600 hover:underline " onClick={handleForgotPassword}>
            Mot de passe oublié
          </span>
        </label>

        <input type="submit" value="Se Connecter" className="submitButton bg-loginBordure hover:bg-blue-900 text-white font-semibold hover:text-white hover:font-bold text-xs sm:text-sm 3xl:text-4xl py-2 px-4 rounded-full xl:w-1/3 sm:w-32 mx-auto mt-4 mb-1 cursor-pointer" />
        </form>
      ) : (
        <form className="flex flex-col w-full mt-6" onSubmit={handleVerify2FA}>
        {factorPending ? (
          <div className="text-center p-6">
            <h3 className="text-xl lg:text-2xl font-bold text-gray-800 mb-4">
               Activez votre double authentification (2FA)
            </h3>
            <p className="text-lg lg:text-xl text-gray-700 mb-6 leading-relaxed">
              Sécurisez votre compte en suivant ces étapes :
            </p>

            <ul className="text-lg text-left mb-6 space-y-3 list-disc list-inside">
              <li>Scannez le QR code ci-dessous avec une application de gestion de 2FA, comme Google Authenticator ou Authy.</li>
              <li>Si vous ne pouvez pas scanner le QR code, entrez la clé secrète manuellement dans l'application.</li>
              <li>Une fois le code généré dans l'application, entrez-le dans le champ "Code 2FA" ci-dessous.</li>
            </ul>

            <img
              src={qrCode}
              alt="QR Code 2FA"
              className="mx-auto my-6 w-48 h-48 lg:w-64 lg:h-64 shadow-md rounded-lg"
            />

            <p className="text-lg font-semibold text-gray-800">Ou entrez cette clé :</p>
            <p className="bg-gray-100 text-lg p-4 rounded-lg shadow-md mx-auto max-w-full overflow-x-auto break-all text-center">
              {secret}
            </p>
          </div>
        ) : (
          <div className="text-center">
            <p className="text-xl lg:text-2xl font-semibold text-gray-800">
              Double authentification déjà activée.
            </p>
            <p className="text-xl lg:text-2xl font-semibold text-gray-800 mt-2">
              Saisissez le code depuis votre application.
            </p>
          </div>
        )}
        <input type="text" name="twoFactorCode" placeholder="Code 2FA" onChange={handle2FAChange} value={twoFactorCode}
          className="border-2 border-blue-300 rounded-lg p-2 my-4 w-full" />
        <label className="flex items-center justify-center mt-2">
          <input type="checkbox" onChange={handleRememberDevice} checked={rememberDevice} className="mr-2" /> Se souvenir de cet appareil (7 jours)
        </label>
        <button type="submit" className="bg-bleuSTB hover:bg-marronSTB  text-white py-2 px-4 rounded-lg mt-4">Vérifier</button>
        <button onClick={() => setTempToken(null)} className="mt-4 text-bleuSTB hover:underline">
            ← Retour à la connexion
          </button>
      </form>
    )}
        <ForgotPassword isShowing={isForgotPasswordModalShowing} hide={toggleForgotPasswordModal} />
        {error && <p className="text-red-600">{error}</p>}
    </div>
  );
}