import React, { useEffect, useState } from "react";
import axios from 'axios';
import Header from "../Header/Header";
import { API_URL } from "../constants";
import { FaChevronUp, FaChevronDown, FaTrash, FaTimes } from "react-icons/fa";
import { useNavigate} from "react-router-dom";
import { UserTypes } from "../constants"
import { useSelector, useDispatch } from 'react-redux';
import { updateBatteryLevelCache } from './actions';


export default function MachinesList(props) {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : props.user;
  const token = user.auth_token;
  console.log(user.auth_token)
  const userType = user.type;
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);
  const [isMobileView, setIsMobileView] = useState(false);
  const [expandedMotors, setExpandedMotors] = useState([]);
  const isAuthorizedUser = [UserTypes.SUPER, UserTypes.ADMIN, UserTypes.CHEFAG ,UserTypes.CHEFAT].includes(userType);
  
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [machineToDelete, setMachineToDelete] = useState(null);
  const [hoveredMachineId, setHoveredMachineId] = useState(null);
  const dispatch = useDispatch();
  const batteryLevelCache = useSelector(state => state.batteryLevelCache);

  const handleDeleteMachine = async () => {
    try {
      await axios.delete(`${API_URL}/machines/${machineToDelete}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      console.log("Machine deleted successfully");
      // Optionally, you can update the machinesData state here to reflect the new list of machines
      // setMachinesData(machinesData.filter((machine) => machine.idm !== machineToDelete));
      setShowDeleteModal(false);
      setMachineToDelete(null);
      window.location.reload();
    } catch (error) {
      console.log("Error deleting machine:", error.message);
    }
  };
  
  const handleShowDeleteModal = (machineId) => {
    setMachineToDelete(machineId);
    setShowDeleteModal(true);
  };

  const [showCreateAgencyForm, setShowCreateAgencyForm] = useState(false);

  const [newAgency, setNewAgency] = useState({
    nom: "",
    adresse: "", 
  });

  const resetNewAgency = () => {
    setNewAgency({
      nom: "",
      adresse: "",
    });
    setAdresseSuggestions([]); // Réinitialiser les suggestions d'adresse lorsque le formulaire est fermé
  };
  

  const [users, setUsers] = useState([]); // State pour stocker les données des utilisateurs
  const [adresseSuggestions, setAdresseSuggestions] = useState([]); // State pour stocker les suggestions d'adresse
  const [adresseQuery, setAdresseQuery] = useState(""); // State pour stocker la valeur du champ adresse

  const fetchAdresseSuggestions = async (query) => {
    try {
      const encodedText = encodeURIComponent(query);
      const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${encodedText}`);
      setAdresseSuggestions(response.data.features);
    } catch (error) {
      console.log("Erreur lors de la récupération des suggestions d'adresse :", error.message);
    }
  };

  // Effectuer la requête API pour les suggestions d'adresse à chaque changement de valeur du champ adresse
  useEffect(() => {
    if (adresseQuery.trim() !== "") {
      fetchAdresseSuggestions(adresseQuery);
    } else {
      setAdresseSuggestions([]);
    }
  }, [adresseQuery]);

  // Fonction pour récupérer les données des utilisateurs depuis l'API
  const fetchUsersData = async () => {
    try {
      const responseUsers = await axios.get(`${API_URL}/users`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      });
  
      if (responseUsers.status !== 200) {
        console.log("Erreur lors de la récupération des utilisateurs.");
        return;
      }
  
      const users = responseUsers.data;
  
      // Filtrer les utilisateurs de type 2 (chefs d'agence) et type 3 (chefs d'atelier) qui ne sont pas liés à une agence
      const chefsAgence = users.filter(user => user.type === 2 && !user.agence);
      const chefsAtelier = users.filter(user => user.type === 3 && !user.agence);
  
      setUsers({ chefsAgence, chefsAtelier });
    } catch (error) {
      console.log("Erreur lors de la récupération des données :", error.message);
    }
  };
  

  useEffect(() => {
    fetchUsersData();
  }, [token]);

  const createNewAgency = async () => {
    try {
  
      console.log("New Agency Data: ", newAgency);
  
      // Créez un objet d'agence sans les utilisateurs et les machines
      const agencyData = {
        nom: newAgency.nom,
        adresse: newAgency.adresse,
      };
  
      const response = await axios.post(`${API_URL}/agences`, agencyData, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      });
  
      console.log("Nouvelle agence créée :", response.data);
  
      setShowCreateAgencyForm(false);
      resetNewAgency();
      window.location.reload();
      setShowValidationMessageAgence(true);
      setTimeout(() => {
        setShowValidationMessageAgence(false);
      }, 5000);
    } catch (error) {
      console.log("Erreur lors de la création de l'agence :", error.message);
    }
  };
  

  const [showCreateTypeForm, setShowCreateTypeForm] = useState(false);

  const [newMachineType, setNewMachineType] = useState({
    nom: "",
    marque: "",
    modele: ""
  });

  const [isTypeFormSubmitted, setIsTypeFormSubmitted] = useState(false);
  const isMachineTypeCreated = showCreateTypeForm && isTypeFormSubmitted;

  const resetNewMachineType = () => {
    setNewMachineType({
      nom: "",
      marque: "",
      modele: ""
    });
  };

  const createNewMachineType = async () => {
    // Valider les champs avant d'envoyer la requête API
    if (!newMachineType.nom || !newMachineType.marque || !newMachineType.modele) {
      // Afficher un message d'erreur ou gérer l'entrée invalide de manière appropriée
      console.log("Veuillez remplir tous les champs du formulaire.");
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/typesmachine`, newMachineType, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      });
      console.log("Nouveau type de machine créé :", response.data);

      setIsTypeFormSubmitted(true);
      setShowCreateTypeForm(false);
      resetNewMachineType();
      window.location.reload();
      setShowValidationMessageType(true);
    setTimeout(() => {
      setShowValidationMessageType(false);
    }, 5000);
    } catch (error) {
      console.log("Erreur lors de la création du type de machine :", error.message);
    }
  };

  const [selectedMachineId, setSelectedMachineId] = useState("");
  const [batteryChargeError, setBatteryChargeError] = useState(false);
  const [showValidationMessageCapteur, setShowValidationMessageCapteur] = useState(false);
  const [showValidationMessageMachine, setShowValidationMessageMachine] = useState(false);
  const [showValidationMessageType, setShowValidationMessageType] = useState(false);
  const [showValidationMessageAgence, setShowValidationMessageAgence] = useState(false);



  const handleSensorInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === "actif") {
      setNewSensor({ ...newSensor, [name]: value === "oui" });
    } else {
      // Pour la charge de batterie, assurez-vous que la valeur est un nombre
      const intValue = name === "batteriec" ? parseInt(value, 10) : value;
      
      setNewSensor({ ...newSensor, [name]: intValue });
    }
  };
  
  
  

  const [machinesWithoutSensor, setMachinesWithoutSensor] = useState([]);

  useEffect(() => {
    const fetchMachines = async () => {
      try {
        const response = await axios.get(`${API_URL}/machines`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
  
        const machines = response.data.data;
  
        // Filtrer les machines pour obtenir celles sans capteur
        const machinesWithoutSensor = machines.filter(machine => machine.capteur === null);
  
        setMachinesWithoutSensor(machinesWithoutSensor);
      } catch (error) {
        console.log("Erreur lors de la récupération des machines :", error.message);
      }
    };
  
    fetchMachines();
  }, [token]);
  
  



  const [showCreateCapteurForm, setShowCreateCapteurForm] = useState(false);
  const [newSensor, setNewSensor] = useState({
    nom: "",
    batterie: 100, // Initialiser à 100 lorsque le formulaire s'ouvre
    actif: true,
  });
  
  const resetNewSensor = () => {
    setNewSensor({
      nom: "",
      batterie: 100, // Réinitialiser à 100
      actif: true,
    });
    setSelectedMachineId("");
    setBatteryChargeError(false); // Réinitialiser l'erreur
  };
  


const createSensorAndAssociateWithMachine = async () => {
  if (
    newSensor.batterie < 0 ||
    newSensor.batterie > 100 ||
    isNaN(newSensor.batterie) ||
    newSensor.nom.trim() === "" ||
    !selectedMachineId
  ) {
    console.log("Saisie invalide pour la création du capteur et l'association à une machine.");
    setBatteryChargeError(true); // Définir l'erreur sur true
    return;
  }

  try {
    const sensorData = {
      nom: newSensor.nom,
      batterie: newSensor.batterie,
      actif: newSensor.actif,
      machine: {
        id: selectedMachineId,
      },
    };

    const response = await axios.post(`${API_URL}/capteurs`, sensorData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    console.log("Nouveau capteur créé et associé à la machine :", response.data);

    setShowCreateCapteurForm(false);
    setShowCreateForm(false);
    setNewSensor({
      nom: "",
      batterie: 100,
      actif: true,
    });
    setSelectedMachineId("");
    window.location.reload();
    setShowValidationMessageCapteur(true);
    setTimeout(() => {
      setShowValidationMessageCapteur(false);
    }, 5000);
  } catch (error) {
    console.log("Erreur lors de la création du capteur et de l'association à la machine :", error.message);
  }
};






  const [showCreateForm, setShowCreateForm] = useState(false);

  const resetNewMachine = () => {
    setNewMachine({
      nom: "",
      commentaire: "",
      heuresUtilisation: 0,
      typeMachine: "",
      agence: ""
    });
  };

  const [newMachine, setNewMachine] = useState({
    nom: "",
    commentaire: "",
    heuresUtilisation: 0,
    typeMachine: null, 
    agence: null, 
  });
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMachine({ ...newMachine, [name]: value });
  };
  
  
  

  // Fonction pour créer une nouvelle machine
  const createNewMachine = async () => {
    // Valider les champs avant d'envoyer la requête API
    if (newMachine.heuresUtilisation < 0 || isNaN(newMachine.heuresUtilisation)) {
      // Afficher un message d'erreur ou gérer l'entrée invalide de manière appropriée
      console.log("Entrée invalide pour la création d'une nouvelle machine.");
      return;
    }
  
    // Créez un objet pour stocker les données de la machine à envoyer dans la requête
    const machineDataToSend = {
      nom: newMachine.nom,
      commentaire: newMachine.commentaire,
      heuresUtilisation: newMachine.heuresUtilisation,
    };
  
    // Ajoutez le type de machine à envoyer s'il est sélectionné
    if (newMachine.typeMachine) {
      machineDataToSend.typeMachine = {
        id: newMachine.typeMachine,
      };
    }
  
    // Ajoutez l'agence à envoyer s'il est sélectionné
    if (newMachine.agence) {
      machineDataToSend.agence = {
        id: newMachine.agence,
      };
    }
  
    try {
      const response = await axios.post(`${API_URL}/machines`, machineDataToSend, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      });
      console.log("Nouvelle machine créée :", response.data);
  
      setShowCreateForm(false);
      setShowCreateCapteurForm(false);
      resetNewMachine();
      window.location.reload();
      setShowValidationMessageMachine(true);
  
      setTimeout(() => {
        setShowValidationMessageMachine(false);
      }, 5000);
    } catch (error) {
      console.log("Erreur lors de la création de la machine :", error.message);
    }
  };
  



  const [searchTerm, setSearchTerm] = useState(""); // State for the search term
  const [selectedMachineType, setSelectedMachineType] = useState(""); // State for selected machine type
  const [selectedAgency, setSelectedAgency] = useState(""); // State for selected agency


  // Function to handle the search bar change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };


  const handleRowHover = (index) => {
    setHoveredRowIndex(index);
  };
  
  const handleRowClick = (index) => {
    if (expandedMotors.includes(index)) {
      setExpandedMotors(expandedMotors.filter((item) => item !== index)); // Si le moteur est déjà étendu, réduisez-le
    } else {
      setExpandedMotors([...expandedMotors, index]);
    }
  };


  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  });

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 1024);
  };

  // State pour stocker les données des machines
  const [machinesData, setMachinesData] = useState([]);

  // State pour stocker les données des types de machines
  const [machineTypesData, setMachineTypesData] = useState([]);

  // State pour stocker les données des agences
  const [agencesData, setAgencesData] = useState([]);

  useEffect(() => {
    const fetchMachinesData = async () => {
      try {
        const response = await axios.get(`${API_URL}/machines`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        });
  
        // Mapping des données de la machine
        const mappedMachines = await Promise.all(
          response.data.data.map(async (machine) => {
            // Récupérer les maintenances de type pour cette machine
            if (machine.typeMachine && machine.typeMachine.id) {
              const typeMachineId = machine.typeMachine.id;
              const typeMachineResponse = await axios.get(`${API_URL}/maintenances/types/${typeMachineId}`, {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token
                }
              });

  
              // Ajouter les maintenances de type à la machine
              machine.typeMaintenances = typeMachineResponse.data.data;
            }
            return machine;
          })
        );
  
        setMachinesData(mappedMachines);
      } catch (error) {
        console.log("Erreur lors de la récupération des données des machines :", error.message);
      }
    };

    // Fonction pour récupérer les données des types de machines depuis l'API
    const fetchMachineTypesData = async () => {
      try {
        const response = await axios.get(`${API_URL}/typesmachine`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        });
        setMachineTypesData(response.data.data);
        console.log(response.data.data)
      } catch (error) {
        console.log("Erreur lors de la récupération des données de types de machines :", error.message);
      }
    };
    // Fonction pour récupérer les données des agences depuis l'API
    const fetchAgencesData = async () => {
      try {
        const response = await axios.get(`${API_URL}/agences`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        });
        setAgencesData(response.data.data);
      } catch (error) {
        console.log("Erreur lors de la récupération des données des agences :", error.message);
      }
    };
    fetchMachinesData();
    fetchMachineTypesData();
    fetchAgencesData();
  }, [token]);
  const filteredMachines = machinesData.filter((machine) => {
    const typeFilter =
      !selectedMachineType || (machine.typeMachine && machine.typeMachine.id === selectedMachineType);
  
    const agencyFilter =
      !selectedAgency || (machine.agence && machine.agence.id === selectedAgency);
  
    return (
      typeFilter &&
      agencyFilter &&
      machine.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  

  const getColorClass = (machine) => {
    if (machine === null) {
      return "bg-gray-200"; // Couleur de fond grise pour les niveaux non renseignés
    }
  
    const level = parseFloat(machine); // Convertir le taux en nombre à virgule flottante
  
    if (level >= 0 && level <= 30) {
      return "bg-red-200"; // Couleur de fond rouge pour les taux entre 0 et 10
    } else if (level > 30 && level <= 60) {
      return "bg-orange-200"; // Couleur de fond jaune pour les taux entre 10 et 30
    } else {
      return "bg-green-200"; // Couleur de fond verte pour les taux entre 30 et 100
    }
  };
  
  const getTextColorClass = (machine) => {
    if (machine === null) {
      return "text-gray-700";
    }
  
    const level = parseFloat(machine);
  
    if (level >= 0 && level <= 30) {
      return "text-red-700";
    } else if (level > 30 && level <= 60) {
      return "text-orange-700";
    } else if (level > 60 && level <= 100) {
      return "text-green-700";
    } else {
      return "text-gray-700";
    }
  };

  const getMaintenanceColorClass = (maintenanceMachines, typeMaintenances) => {
    if ((!maintenanceMachines || maintenanceMachines.length === 0) && 
        (!typeMaintenances || typeMaintenances.length === 0)) {
      return "bg-gray-200"; // Couleur de fond grise pour aucune maintenance
    }
  
    if ((maintenanceMachines && maintenanceMachines.some((maintenance) => maintenance.etat === "A_FAIRE")) ||
        (typeMaintenances && typeMaintenances.some((maintenance) => maintenance.etat === "A_FAIRE"))) {
      return "bg-red-200"; // Couleur de fond rouge s'il y a des maintenances "A_FAIRE"
    } else if ((maintenanceMachines && maintenanceMachines.some((maintenance) => maintenance.etat === "A_PREVOIR")) ||
               (typeMaintenances && typeMaintenances.some((maintenance) => maintenance.etat === "A_PREVOIR"))) {
      return "bg-orange-200"; // Couleur de fond orange s'il y a des maintenances "A_PREVOIR"
    } else {
      return "bg-green-200"; // Couleur de fond verte pour les autres cas (A_JOUR)
    }
  };
  
  const getMaintenanceTextColorClass = (maintenanceMachines, typeMaintenances) => {
    if ((!maintenanceMachines || maintenanceMachines.length === 0) &&
        (!typeMaintenances || typeMaintenances.length === 0)) {
      return "text-gray-700"; // Texte gris pour aucune maintenance
    }
  
    if ((maintenanceMachines && maintenanceMachines.some((maintenance) => maintenance.etat === "A_FAIRE")) ||
        (typeMaintenances && typeMaintenances.some((maintenance) => maintenance.etat === "A_FAIRE"))) {
      return "text-red-700"; // Texte rouge s'il y a des maintenances "A_FAIRE"
    } else if ((maintenanceMachines && maintenanceMachines.some((maintenance) => maintenance.etat === "A_PREVOIR")) ||
               (typeMaintenances && typeMaintenances.some((maintenance) => maintenance.etat === "A_PREVOIR"))) {
      return "text-orange-700"; // Texte orange s'il y a des maintenances "A_PREVOIR"
    } else {
      return "text-green-700"; // Texte vert pour les autres cas (A_JOUR)
    }
  };
  
  
      // Fonction pour déterminer la priorité de maintenance (A_FAIRE, A_PREVOIR, A_JOUR)
const getMaintenancePriority = (maintenanceMachines) => {
  if (maintenanceMachines.some((maintenance) => maintenance.etat === "A_FAIRE")) {
    return 1;
  } else if (maintenanceMachines.some((maintenance) => maintenance.etat === "A_PREVOIR")) {
    return 2;
  } else {
    return 3;
  }
};
  
  
  
  return (
    <div className="min-h-screen flex flex-col text-base sm:text-3xl">
      <Header user={user} />
      <div className="flex flex-auto h-full justify-center">
      {isAuthorizedUser ? (
        <div className="p-4 h-fit flex flex-col items-center">
          <h2 className="text-loginBordure underline decoration-3 underline-offset-8 decoration-orangeButton font-bold mb-10 text-3xl xl:text-5xl 3xl:text-6xl">Liste des machines</h2>
          {/* Search bar */}
          <input
            type="text"
            placeholder="Rechercher une machine..."
            className="px-2 py-1 rounded-md border border-gray-300 mb-4"
            value={searchTerm}
            onChange={handleSearchChange}
          />

          {/* Filters */}
          <div className="grid gap-4 grid-cols-1 lg:grid-cols-2">
            {/* Machine Type Filter */}
            <select
              className="px-2 py-1 rounded-md border border-gray-300"
              value={selectedMachineType}
              onChange={(e) => setSelectedMachineType(e.target.value)}
            >
              <option value="">Tous les types de machines</option>
              {machineTypesData.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.nom}
                </option>
              ))}
            </select>

            {/* Agency Filter */}
            <select
              className="px-2 py-1 rounded-md border border-gray-300"
              value={selectedAgency}
              onChange={(e) => setSelectedAgency(e.target.value)}
            >
              <option value="">Toutes les agences</option>
              {agencesData.map((agency) => (
                <option key={agency.id} value={agency.id}>
                  {agency.nom}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col space-y-4 items-center sm:flex-row mt-5 mb-5 ">
          {/* Afficher le bouton "Créer une machine" si le nouveau type de machine est créé */}
          {(!showCreateTypeForm || isMachineTypeCreated) && !showCreateForm && !showCreateAgencyForm && !showCreateCapteurForm && (
            <button
              className="mt-4 bg-loginBordure hover:bg-blue-900 text-white font-semibold hover:text-white hover:font-bold text-2xl rounded-2xl py-4 px-6 mr-4 cursor-pointer 3xl:text-4xl 3xl:px-10 3xl:py-10"
              onClick={() => setShowCreateCapteurForm(true)}
              // Afficher le bouton seulement pour le type d'utilisateur SUPER
              style={{ display: userType === UserTypes.SUPER ? "block" : "none" }}
            >
              Créer un capteur
            </button>
          )}
          {(!showCreateTypeForm || isMachineTypeCreated) && !showCreateForm && !showCreateAgencyForm && !showCreateCapteurForm && (
            <button
              className="mt-4 bg-loginBordure hover:bg-blue-900 text-white font-semibold hover:text-white hover:font-bold text-2xl rounded-2xl py-4 px-6 mr-4 cursor-pointer 3xl:text-4xl 3xl:px-10 3xl:py-10"
              onClick={() => setShowCreateForm(true)}
            >
              Créer une machine
            </button>
          )}
          {/* Afficher le bouton "Créer un type de machine" si le nouveau type de machine n'est pas encore créé */}
          {!showCreateCapteurForm && !isMachineTypeCreated && !showCreateForm && !showCreateTypeForm && !showCreateAgencyForm && (
            <button
              className="bg-loginBordure hover:bg-blue-900 text-white font-semibold hover:text-white hover:font-bold text-2xl rounded-2xl py-4 px-6 mr-4 cursor-pointer 3xl:text-4xl 3xl:px-10 3xl:py-10"
              onClick={() => setShowCreateTypeForm(true)}
            >
              Créer un type de machine
            </button>
          )}
          {!showCreateAgencyForm && !showCreateForm && !showCreateTypeForm && !showCreateCapteurForm && (
            <button
              className="bg-loginBordure hover:bg-blue-900 text-white font-semibold hover:text-white hover:font-bold text-2xl rounded-2xl py-4 px-6 cursor-pointer 3xl:text-4xl 3xl:px-10 3xl:py-10"
              onClick={() => setShowCreateAgencyForm(true)}
            >
              Créer une agence
            </button>
          )}
        </div>
      {showCreateAgencyForm && (
        <div className="border-2 border-loginBordure rounded-lg p-4 mt-4 mb-8 lg:mb-0">
          <div className="flex justify-end">
          <button
            className="text-red-600 hover:text-red-700 text-2xl focus:outline-none"
            onClick={() => {setShowCreateAgencyForm(false);resetNewAgency();}}
          >
            <FaTimes />
          </button>
          </div>
          <h1 className="text-center mb-5 underline text-3xl">Création d'une agence</h1>         
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="text"
              placeholder="Nom de l'agence"
              className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              name="nom"
              value={newAgency.nom}
              onChange={(e) => setNewAgency({ ...newAgency, nom: e.target.value })}
            />
            <input
              type="text"
              placeholder="Adresse de l'agence"
              className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              name="adresse"
              value={newAgency.adresse}
              onChange={(e) => {
                setNewAgency({ ...newAgency, adresse: e.target.value });
                setAdresseQuery(e.target.value); // Mettre à jour la valeur du champ adresse pour déclencher la requête API
              }}
            />
            {/* Afficher les suggestions d'adresse sous le champ */}
            {adresseSuggestions.length > 0 && (
              <ul className="border rounded-md bg-white absolute mt-32 md:mt-14 w-full">
                {adresseSuggestions.map((suggestion) => (
                  <li
                    key={suggestion.properties.id}
                    className="cursor-pointer p-2 hover:bg-gray-100"
                    onClick={() => {
                      setNewAgency({ ...newAgency, adresse: suggestion.properties.label });
                      setAdresseSuggestions([]); // Cacher les suggestions après avoir sélectionné une adresse
                    }}
                  >
                    {suggestion.properties.label}
                  </li>
                ))}
              </ul>
            )}
            {/*<select
              className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              name="chefagence"
              value={newAgency.chefagence}
              onChange={(e) => setNewAgency({ ...newAgency, chefagence: parseInt(e.target.value) })}
            >
              <option value="" disabled>Sélectionnez le chef d'agence</option>
              {users.chefsAgence.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.nom} {user.prenom}
                </option>
              ))}
            </select>

            <select
              className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              name="chefatelier"
              value={newAgency.chefatelier}
              onChange={(e) => setNewAgency({ ...newAgency, chefatelier: parseInt(e.target.value) })}
            >
              <option value="" disabled>Sélectionnez le chef d'atelier</option>
              {users.chefsAtelier.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.nom} {user.prenom}
                </option>
              ))}
              </select>*/}
          </div>
          <div className="flex justify-center mt-4">
            <button
              className="bg-loginBordure hover:bg-blue-900 text-white hover:text-white hover:font-bold px-4 py-2 rounded-md"
              onClick={() => {
                createNewAgency();
                resetNewAgency();
                setShowCreateAgencyForm(false);
              }}
            >
              Valider
            </button>
          </div>
        </div>
      )}
          {showCreateTypeForm && (
            <div className="border-2 border-loginBordure rounded-lg p-4 mt-4 mb-8 lg:mb-0">
               <div className="flex justify-end">
            <button
              className="text-red-600 hover:text-red-700 text-2xl focus:outline-none"
              onClick={() => setShowCreateTypeForm(false)}
            >
              <FaTimes />
            </button>
          </div>
              <h1 className="text-center mb-5 underline text-3xl">Création d'un type de machine</h1>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <input
                  type="text"
                  placeholder="Nom du type de machine"
                  className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="nomtypem"
                  value={newMachineType.nom}
                  onChange={(e) => setNewMachineType({ ...newMachineType, nom: e.target.value })}
                />
                <input
                  type="text"
                  placeholder="Marque du type de machine"
                  className="sm:text-2xl px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="marquem"
                  value={newMachineType.marque}
                  onChange={(e) => setNewMachineType({ ...newMachineType, marque: e.target.value })}
                />
                <input
                  type="text"
                  placeholder="Modèle du type de machine"
                  className="sm:text-2xl px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="modelem"
                  value={newMachineType.modele}
                  onChange={(e) => setNewMachineType({ ...newMachineType, modele: e.target.value })}
                />
              </div>
              <div className="flex justify-center mt-4">
                <button
                  className="bg-loginBordure hover:bg-blue-900 text-white hover:text-white hover:font-bold px-4 py-2 rounded-md"
                  onClick={() => {
                    createNewMachineType();
                    resetNewMachineType();
                    setShowCreateTypeForm(false);
                  }}
                >
                  Valider
                </button>
              </div>
            </div>
          )}
          {showCreateForm && (
            <div className="border-2 border-loginBordure rounded-lg p-4 mt-4 mb-8 lg:mb-0">
              <div className="flex justify-end">
            <button
              className="text-red-600 hover:text-red-700 text-2xl focus:outline-none"
              onClick={() => setShowCreateForm(false)}
            >
              <FaTimes />
            </button>
          </div>
              <h1 className="text-center mb-5 underline text-3xl">Création d'une machine</h1>
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                <input
                  type="text"
                  placeholder="Nom de la machine"
                  className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="nom"
                  value={newMachine.nomm}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  placeholder="Commentaires de la machine"
                  className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="commentaire"
                  value={newMachine.commentairem}
                  onChange={handleInputChange}
                />
                <input
                  type="number"
                  placeholder="Durée de fonctionnement avant création de la machine"
                  className="sm:text-lg px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="heuresUtilisation"
                  onChange={handleInputChange}
                />
                <select
                  className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="typeMachine"
                  value={newMachine.typeMachine}
                  onChange={handleInputChange}
                >
                  <option value="">Sélectionner un type de machine</option>
                  {machineTypesData.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.nom}
                    </option>
                  ))}
                </select>
                <select
                  className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="agence"
                  value={newMachine.agence}
                  onChange={handleInputChange}
                >
                  <option value="">Sélectionner une agence</option>
                  {agencesData.map((agency) => (
                    <option key={agency.id} value={agency.id}>
                      {agency.nom}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex justify-center mt-4">
                <button
                  className="bg-loginBordure hover:bg-blue-900 text-white hover:text-white hover:font-bold px-4 py-2 rounded-md"
                  onClick={() => {
                    createNewMachine();
                    resetNewMachine();
                    setShowCreateForm(false);
                  }}
                >
                  Valider
                </button>
              </div>
            </div>
          )}
          {showCreateCapteurForm && (
            <div className="border-2 border-loginBordure rounded-lg p-4 mt-4 mb-8 lg:mb-0">
              <div className="flex justify-end">
                <button
                  className="text-red-600 hover:text-red-700 text-2xl focus:outline-none"
                  onClick={() => setShowCreateCapteurForm(false)}
                >
                  <FaTimes />
                </button>
              </div>
              <h1 className="text-center mb-5 underline text-3xl">Création d'un capteur</h1>
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                <input
                  type="text"
                  placeholder="Nom du capteur"
                  className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="nom"
                  value={newSensor.nom}
                  onChange={handleSensorInputChange}
                />
               <input
                  type="number"
                  placeholder="Niveau de charge batterie"
                  className="sm:text-2xl px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="batterie"
                  value={newSensor.batterie} // Afficher la valeur actuelle
                  onChange={handleSensorInputChange} // Gérer les changements
                  min="0"
                  max="100"
                />
                <select
                  className="px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="selectedMachineId"
                  value={selectedMachineId}
                  onChange={(e) => setSelectedMachineId(e.target.value)}
                >
                  <option value="">Sélectionner une machine</option>
                  {machinesWithoutSensor.map(machine => (
                    <option key={machine.id} value={machine.id}>
                      {machine.nom}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex justify-center mt-4">
              <button
                className="bg-loginBordure hover:bg-blue-900 text-white hover:text-white hover:font-bold px-4 py-2 rounded-md"
                onClick={() => {
                  createSensorAndAssociateWithMachine();
                  resetNewSensor();
                  setShowCreateCapteurForm(false);
                }}
              >
                Valider
              </button>
              </div>
              {batteryChargeError && (
                <p className="bg-red-500 rounded-md text-white text-2xl px-4 py-2 text-center mt-4">Le niveau de charge de la batterie doit être entre 0 et 100 %</p>)}
            </div>
          )}
          {showValidationMessageCapteur && (
            <p className="bg-green-600 rounded-md text-white text-xl px-4 py-2 text-center w-auto">Le capteur a été créé avec succès !</p>
          )}
          {showValidationMessageMachine && (
            <p className="bg-green-600 rounded-md text-white text-xl px-4 py-2 text-center w-auto">La machine a été créée avec succès !</p>
          )}
          {showValidationMessageType && (
            <p className="bg-green-600 rounded-md text-white text-xl px-4 py-2 text-center w-auto">Le type de machine a été créé avec succès !</p>
          )}
          {showValidationMessageAgence && (
            <p className="bg-green-600 rounded-md text-white text-xl px-4 py-2 text-center w-auto">L'agence a été créée avec succès !</p>
          )}

{isMobileView ? (
  <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
    {filteredMachines
      .slice()
      .sort((machineA, machineB) => {
        const batteryLevelA = parseFloat(machineA.capteur?.batterie) || -1;
        const batteryLevelB = parseFloat(machineB.capteur?.batterie) || -1;

        if (batteryLevelA !== batteryLevelB) {
          return batteryLevelA - batteryLevelB;
        } else {
          const maintenanceA = getMaintenancePriority(machineA.maintenanceMachines);
          const maintenanceB = getMaintenancePriority(machineB.maintenanceMachines);

          if (maintenanceA !== maintenanceB) {
            return maintenanceA - maintenanceB;
          } else {
            return machineA.nom.localeCompare(machineB.nom);
          }
        }
      })
      .map((machine, index) => (
        <div
          className={`flex flex-col mb-4 md:mb-6 bg-white shadow-md rounded-lg border border-gray-300 ${
            index === hoveredRowIndex ? "bg-gray-100" : ""
          }`}
          onClick={() => {
            navigate("/details");
            if (localStorage.getItem("user")) {
              localStorage.setItem("machine", JSON.stringify(machine));
            }
          }}
          key={machine.id}
          onMouseEnter={() => handleRowHover(index)}
          onMouseLeave={() => handleRowHover(-1)}
        >
          <div className="flex flex-col p-4 space-y-2">
            <div className="flex items-center justify-between">
              <span className="text-sm md:text-lg text-loginBordure font-bold truncate">{machine.nom}</span>
              <button
                type="button"
                className="ml-2 text-gray-500"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRowClick(index);
                }}
              >
                {expandedMotors.includes(index) ? <FaChevronUp /> : <FaChevronDown />}
              </button>
            </div>

            <div className="text-xs md:text-sm">
              <span className="font-bold text-loginBordure">Agence: </span>
              {machine.agence ? machine.agence.nom : "Aucune agence"}
            </div>
            <div className="text-xs md:text-sm">
              <span className="font-bold text-loginBordure">Adresse: </span>
              {machine.agence ? machine.agence.adresse : "Aucune adresse"}
            </div>
            <div className={`text-xs md:text-sm ${getMaintenanceTextColorClass(machine.maintenanceMachines)}`}>
              <span className="font-bold text-loginBordure">Maintenance: </span>
              {machine.maintenanceMachines.length === 0 && (!machine.typeMaintenances || machine.typeMaintenances.length === 0)
                ? "Aucun état de maintenance"
                : machine.maintenanceMachines.some((maintenance) => maintenance.etat === "A_FAIRE")
                ? "A faire"
                : machine.maintenanceMachines.every((maintenance) => maintenance.etat === "A_JOUR")
                ? "A jour"
                : "A prévoir"}
            </div>
          </div>

          {expandedMotors.includes(index) && (
            <div className="p-4 max-h-64 overflow-auto">
              <div className="text-xs md:text-sm">
                <span className="font-bold text-loginBordure">Type de machine: </span>
                {machine.typeMachine?.nom || "Aucun type de machine"}
              </div>
              <div className="text-xs md:text-sm mt-2">
                <span className="font-bold text-loginBordure">Marque: </span>
                {machine.typeMachine?.marque || "Aucune marque"}
              </div>
              <div className="text-xs md:text-sm mt-2">
                <span className="font-bold text-loginBordure">Modèle: </span>
                {machine.typeMachine?.modele || "Aucun modèle"}
              </div>
              <div className="text-xs md:text-sm mt-2">
                <span className="font-bold text-loginBordure">Temps d'utilisation: </span>
                {machine.heuresUtilisation}h
              </div>
              {userType === UserTypes.SUPER ? (() => {
                const adjustedBatteryLevel =
                  machine.capteur?.batterie !== undefined && machine.capteur?.batterie !== null
                    ? Math.min(100, Math.max(0, parseFloat(machine.capteur.batterie)))
                    : null;

                return (
                  <div className="text-xs md:text-sm mt-2">
                    <span className="font-bold text-loginBordure">Niveau de charge de la batterie: </span>
                    <span className={`${getTextColorClass(adjustedBatteryLevel)} ${getColorClass(adjustedBatteryLevel)}`}>
                      {adjustedBatteryLevel !== null ? `${adjustedBatteryLevel}%` : "Non renseigné"}
                    </span>
                  </div>
                );
              })() : null}
              <div className="flex justify-end mt-4">
                <button
                  className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowDeleteModal(machine.id);
                  }}
                >
                  Supprimer
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
  </div>
) : (
            <table className="cursor-pointer text-xs md:text-sm lg:text-base xl:text-xl 2xl:text-2xl 3xl:text-3xl mt-5">
  <thead className="hidden lg:contents">
    <tr>
      <th scope="col" className="bg-orangeButton text-white p-6 text-center border-2">
        Machine
      </th>
      <th scope="col" className="bg-orangeButton text-white p-2 text-center border-2">
        Type de machine
      </th>
      <th scope="col" className="bg-orangeButton text-white p-2 text-center border-2">
        Marque
      </th>
      <th scope="col" className="bg-orangeButton text-white p-2 text-center border-2">
        Durée totale de fonctionnement
      </th>
      <th scope="col" className="bg-orangeButton text-white p-2 text-center border-2">
        Nom de l'agence
      </th>
      {userType === UserTypes.SUPER ? (
        <th scope="col" className="bg-orangeButton text-white p-2 text-center border-2">
          Niveau de charge de la batterie du capteur
        </th>
      ) : null}
      <th scope="col" className="bg-orangeButton text-white p-2 text-center border-2">
        Maintenance
      </th>
    </tr>
  </thead>
  <tbody>
    {/* Affichage des données des machines dans le tableau */}
    {filteredMachines
    
    .sort((machineA, machineB) => {
      // Compare le niveau de batterie
      const batteryLevelA = parseFloat(machineA.capteur?.batterie) || -1;
      const batteryLevelB = parseFloat(machineB.capteur?.batterie) || -1;
    
      if (batteryLevelA !== batteryLevelB) {
        return batteryLevelA - batteryLevelB; // Trie par charge de batterie croissante
      } else {
        // Si les niveaux de batterie sont égaux, compare l'état de maintenance
        const maintenanceA = getMaintenancePriority(machineA.maintenanceMachines);
        const maintenanceB = getMaintenancePriority(machineB.maintenanceMachines);
    
        if (maintenanceA !== maintenanceB) {
          return maintenanceA - maintenanceB; // Trie par priorité de maintenance
        } else {
          // Si les priorités de maintenance sont égales, compare par nom de machine
          return machineA.nom.localeCompare(machineB.nom);
        }
      }
    })
    
    .map((machine, index) => (
      <tr
      className={`flex flex-col mb-10 ${
        index === hoveredRowIndex ? "bg-header" : ""
      } lg:table-row`}
      key={machine.id}
      onClick={() => {
        navigate("/details");
        if (localStorage.getItem("user")) {
          localStorage.setItem("machine", JSON.stringify(machine));
        }
      }}
      onMouseEnter={() => handleRowHover(index)}
      onMouseLeave={() => handleRowHover(-1)}
    >
        <td className="border-2 p-2 text-center">{machine.nom}</td>
        <td className="border-2 p-2 text-center">
          {machine.typeMachine ? machine.typeMachine.nom : "Aucun type de machine"}
        </td>
        <td className="border-2 p-2 text-center">
          {machine.typeMachine ? machine.typeMachine.marque : "Aucune marque"}
        </td>
        <td className="border-2 p-2 text-center">{machine.heuresUtilisation}h</td>
        <td className="border-2 p-2 text-center">
          {machine.agence ? machine.agence.nom : "Aucune agence"}
        </td>
        {userType === UserTypes.SUPER ? (() => {
          // Ajuste le niveau de batterie pour qu'il soit entre 0 et 100
          const adjustedBatteryLevel = machine.capteur?.batterie !== undefined && machine.capteur?.batterie !== null
          ? Math.min(100, Math.max(0, parseFloat(machine.capteur.batterie))) // Limite entre 0 et 100
          : null;
          return (
            <td className={`border-2 p-2 text-center ${getTextColorClass(adjustedBatteryLevel)} ${getColorClass(adjustedBatteryLevel)}`}>
              {machine.capteur !== undefined && machine.capteur !== null
                ? machine.capteur.batterie !== undefined && machine.capteur.batterie !== null
                  ? `${adjustedBatteryLevel} %` // Utilise la valeur ajustée pour l'affichage
                  : "Capteur présent, mais niveau de batterie non renseigné"
                : "Pas de capteur"}
            </td>
          );
        })() : null}
<td className={`border-2 p-2 text-center ${getMaintenanceTextColorClass(machine.maintenanceMachines, machine.typeMaintenances)} ${getMaintenanceColorClass(machine.maintenanceMachines, machine.typeMaintenances)}`}>
  {machine.maintenanceMachines.length === 0 && (!machine.typeMaintenances || machine.typeMaintenances.length === 0) && "Aucun état de maintenance"}

  {(machine.maintenanceMachines.length > 0 || (machine.typeMaintenances && machine.typeMaintenances.length > 0)) && (
    <>
      {machine.maintenanceMachines.some((maintenance) => maintenance.etat === "A_FAIRE") || (machine.typeMaintenances && machine.typeMaintenances.some((maintenance) => maintenance.etat === "A_FAIRE")) ? (
        <div>
          A faire{" "}
          <span>({machine.maintenanceMachines.concat(machine.typeMaintenances || []).filter((maintenance) => maintenance.etat === "A_FAIRE").length})</span>
        </div>
      ) : null}
      {machine.maintenanceMachines.some((maintenance) => maintenance.etat === "A_PREVOIR") || (machine.typeMaintenances && machine.typeMaintenances.some((maintenance) => maintenance.etat === "A_PREVOIR")) ? (
        <div>
          A prévoir{" "}
          <span>({machine.maintenanceMachines.concat(machine.typeMaintenances || []).filter((maintenance) => maintenance.etat === "A_PREVOIR").length})</span>
        </div>
      ) : null}
      {machine.maintenanceMachines.every((maintenance) => maintenance.etat === "A_JOUR") && (machine.typeMaintenances ? machine.typeMaintenances.every((maintenance) => maintenance.etat === "A_JOUR") : true) ? (
        <div>
          A jour{" "}
          <span>({machine.maintenanceMachines.concat(machine.typeMaintenances || []).filter((maintenance) => maintenance.etat === "A_JOUR").length})</span>
        </div>
      ) : null}
    </>
  )}
</td>
        <td className="border-2 p-2 text-center">
          {/* Delete button with modal */}
          <button
            className="text-orangeButton hover:text-orange-600 text-3xl cursor-pointer"
            onClick={(e) => {
              e.stopPropagation(); // Empêcher la propagation de l'événement à la ligne du tableau
              handleShowDeleteModal(machine.id);
            }}
          >
            <FaTrash />
          </button>
        </td>
      </tr>
    ))}
  </tbody>
</table>

        )}
        {showDeleteModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded-lg">
              <p className="text-lg">Êtes-vous sûr(e) de vouloir supprimer cette machine ?</p>
              <div className="flex justify-end mt-4">
                <button
                  className="bg-loginBordure hover:bg-gray-300 text-white hover:text-loginBordure hover:font-bold px-4 py-2 rounded-md mr-2"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Annuler
                </button>
                <button
                  className="bg-red-600 hover:bg-red-700 text-white hover:text-white px-4 py-2 rounded-md"
                  onClick={handleDeleteMachine}
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        )}
        </div>
        ) : (
          <p className="text-red-500 font-bold">Vous n'avez pas l'autorisation d'accéder à cette page.</p>
        )}
      </div>
    </div>
  );
}