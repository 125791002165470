import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../constants";
import "./admin.css";
import axios from 'axios';

export default function AddUserForm({ rafraichir, token }) {
  const [inscriptionUser, setInscriptionUser] = useState({ nom: "", prenom: "", mail: "", mdp: "", type: "" });
  const navigate = useNavigate();
  const [messageMailUser, setMessageMailUser] = useState("");
  const [messageMailInvalide, setMessageMailInvalide] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [erreurEmailExistant, setErreurEmailExistant] = useState(false);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false); 

  const clearMessages = () => {
    setTimeout(() => {
      setMessageMailUser("");
      setMessageMailInvalide("");
      setErreurEmailExistant(false);
    }, 5000);
  };

  useEffect(() => {
    clearMessages();
  }, [messageMailUser, messageMailInvalide , erreurEmailExistant]);

  const handleChange = (event) => {
    const { name, type, checked, value } = event.target;
    if (type === "checkbox") {
      if (name === "isAdmin") setIsAdmin(checked);
      if (name === "twoFactorEnabled") setTwoFactorEnabled(checked);
    } else {
      setInscriptionUser({ ...inscriptionUser, [name]: value });
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const showMessagePopup = (message) => {
    alert(message);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateEmail(inscriptionUser.mail)) {
      setMessageMailInvalide("Adresse e-mail invalide");
      return;
    }

    if (isNaN(inscriptionUser.type) || inscriptionUser.type < 0 || inscriptionUser.type > 4) {
      setMessageMailInvalide("Le champ Type doit être un nombre entre 0 et 4");
      return;
    }

    axios
      .post(`${API_URL}/users/register`, {
        nom: inscriptionUser.nom,
        prenom: inscriptionUser.prenom,
        mail: inscriptionUser.mail,
        password: inscriptionUser.password,
        type: parseInt(inscriptionUser.type),
        twoFactorEnabled: twoFactorEnabled,
        twoFactorPending: twoFactorEnabled,
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        }
      })
      .then((response) => {
        console.log(response)
        if (response.status === 401) {
          showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
          localStorage.clear();
          navigate.push("/login");
        } else if (response.status === 409) { 
          setErreurEmailExistant(true); 
          setMessageMailInvalide("L'adresse e-mail est déjà utilisée !");
        } else {
          return response.data;
        }
      })
      .then(
        (result) => {
          if (result.message) {
            setMessageMailUser(result.message);
          }
          rafraichir();
          window.location.reload();
          setInscriptionUser({ nom: "", prenom: "", mail: "", password: "", type: "" });
        },
        (error) => {
          console.log(error.response.data);

          if (error.response.data.statusCode === 401) {
            showMessagePopup("ERREUR : Votre session a expiré. Veuillez vous reconnecter pour continuer.");
            localStorage.clear();
            navigate.push("/login");
          } else if (error.response.data.statusCode === 409) { 
            setErreurEmailExistant(true); 
          }
        }
      );
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col justify-center text-center">
      <label className="flex flex-col text-base lg:text-2xl text-center text-loginBordure 3xl:text-3xl">
        Ajouter un Utilisateur
        <input
          className="text-sm md:text-base lg:text-xl 3xl:text-2xl border-4 border-loginBordure rounded-2xl p-4 text-center mt-4 mb-4"
          type="text"
          name="nom"
          placeholder="Nom"
          onChange={handleChange}
          value={inscriptionUser.nom}
        />
        <input
          className="text-sm md:text-base lg:text-xl 3xl:text-2xl border-4 border-loginBordure rounded-2xl p-4 text-center mt-4 mb-4"
          type="text"
          name="prenom"
          placeholder="Prénom"
          onChange={handleChange}
          value={inscriptionUser.prenom}
        />
        <input
          className="text-sm md:text-base lg:text-xl 3xl:text-2xl border-4 border-loginBordure rounded-2xl p-4 text-center mt-4 mb-4"
          type="email"
          name="mail"
          placeholder="Adresse mail"
          onChange={handleChange}
          value={inscriptionUser.mail}
        />
        <input
          className="text-sm md:text-base lg:text-xl 3xl:text-2xl border-4 border-loginBordure rounded-2xl p-4 text-center mt-4 mb-4"
          type="password"
          name="password"
          placeholder="Mot de passe"
          onChange={handleChange}
          value={inscriptionUser.password}
        />
        <select
          className="text-sm md:text-base lg:text-xl 3xl:text-2xl border-4 border-loginBordure rounded-2xl p-4 text-center mt-4 mb-4"
          name="type"
          onChange={handleChange}
          value={inscriptionUser.type}
        >
          <option value="">Niveau d'accès</option>
          <option value="0">SuperAdmin</option>
          <option value="1">Admin</option>
          <option value="2">Chef d'agence</option>
          <option value="3">Chef d'atelier</option>
          <option value="4">Capteur</option>
        </select>
      </label>
      <label className="flex items-center justify-center space-x-3 mt-4 mb-4">
        <input type="checkbox" name="twoFactorEnabled" checked={twoFactorEnabled} onChange={handleChange} />
        <span className="text-lg lg:text-xl">Activer la double authentification</span>
      </label>
      <input type="submit" value="Ajouter" className="bg-loginBordure hover:bg-blue-900 text-white font-semibold hover:text-white hover:font-bold lg:text-sm 3xl:text-xl border-none rounded-full p-2 w-11/12 mt-5 lg:mt-0 lg:w-60 mr-auto lg:ml-8 cursor-pointer" />
      {erreurEmailExistant && <p className="bg-error rounded-lg text-white text-xl mx-auto p-2 text-center mt-8 flex items-center justify-center">L'adresse e-mail est déjà utilisée !</p>}
      {messageMailUser && <p className="bg-green-500 rounded-lg text-white mx-auto p-2 text-center my-4 text-xl flex items-center justify-center">{messageMailUser}</p>}
      {messageMailInvalide && <p className="bg-error rounded-lg text-white text-xl mx-auto p-2 text-center mt-8 flex items-center justify-center">{messageMailInvalide}</p>}
    </form>
  );
}
