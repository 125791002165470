import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaUserPlus, FaUserMinus, FaTrashAlt, FaEdit } from "react-icons/fa";
import { FaUserShield, FaUserCog, FaUserTie, FaUser } from "react-icons/fa";
import "./admin.css";
import useModal from '../Modal/useModal';
import Modal from '../Modal/modal';
import axios from 'axios';
import { API_URL } from "../constants";
import capteur from "../icons/capteur.png"
import capteur2 from "../icons/capteur2.png"
import { UserTypes } from "../constants";

export default function UserTable({ users, deleteUser, setSelectedUserId, setSelectedUserEmail, rafraichir, token}) {
  const user = JSON.parse(localStorage.getItem("user"));
  const { isShowing: isSuppModalShowing, toggle: toggleSuppModal } = useModal();
  const [selectedAgency, setSelectedAgency] = useState("");
  const [agencies, setAgencies] = useState([]);
  const [agencyUsers, setAgencyUsers] = useState({});
  const [usersInSelectedAgency, setUsersInSelectedAgency] = useState([]); // Nouvel état pour stocker les utilisateurs filtrés par agence
  const [filteredUsers, setFilteredUsers] = useState([]); // Nouvel état pour stocker les utilisateurs filtrés par rôle et par agence
  const userType = user.type;
  const isAdmin = userType === UserTypes.ADMIN;
  const [userToDelete, setUserToDelete] = useState({ mail: "", id: "" });

  const navigate = useNavigate();

  const handleConfirmation = (userId, emailUtilisateur) => {
    // Créez un objet avec le mail de l'utilisateur
    const userData = { mail: emailUtilisateur };
    deleteUser(userId, userData); // Passez également userData à la fonction deleteUser
    toggleSuppModal()
  };
  

  const handleAgencyChange = (event) => {
    setSelectedAgency(event.target.value);
  };

  const showMessagePopup = (message) => {
    alert(message);
  };

  useEffect(() => {
    const fetchAgenciesAndUsers = async () => {
      try {
        const agenciesResponse = await axios.get(`${API_URL}/agences`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        });

        const agencies = agenciesResponse.data.data;
        setAgencies(agencies);

        const updatedAgencyUsers = {};

        for (const agency of agencies) {
          const usersResponse = await axios.get(`${API_URL}/agences/${agency.id}/users`, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            }
          });
          const usersInAgency = usersResponse.data.data.filter(user => {
            for (const agencyId in updatedAgencyUsers) {
              if (agencyId !== agency.id) {
                const users = updatedAgencyUsers[agencyId];
                if (users.find(agencyUser => agencyUser.id === user.id)) {
                  return false;
                }
              }
            }
            return true;
          });

          updatedAgencyUsers[agency.id] = usersInAgency;
        }

        updatedAgencyUsers[""] = users;
        setAgencyUsers(updatedAgencyUsers);

        if (selectedAgency) {
          setUsersInSelectedAgency(updatedAgencyUsers[selectedAgency] || []);
        } else {
          setUsersInSelectedAgency(users);
        }

        console.log("Utilisateurs par agence:", updatedAgencyUsers);
      } catch (error) {
        console.error("Erreur lors de la récupération des informations d'agences et d'utilisateurs :", error.message);
      }
    };

    fetchAgenciesAndUsers();
  }, [selectedAgency, users, token]);

  useEffect(() => {
    const filteredUsers = usersInSelectedAgency.filter(currentUser => {
      const isSuperAdmin = currentUser.type === 0;
      const isCapteur = currentUser.type === 4;

      if ((isSuperAdmin && isAdmin) || (isCapteur && isAdmin)) {
        return false;
      }

      return true;
    });

    setFilteredUsers(filteredUsers);
  }, [usersInSelectedAgency, isAdmin]);

  const getAccessLevelIcon = (userType) => {
    switch (userType) {
      case 0:
        return <FaUserShield className="ml-4 inline-block text-red-500" />;
      case 1:
        return <FaUserCog className="ml-4 inline-block text-yellow-500" />;
      case 2:
        return <FaUserTie className="ml-4 inline-block text-green-500" />;
      case 3:
        return <FaUserTie className="ml-4 inline-block text-blue-500" />;
      case 4:
        return <img src={capteur2} alt="Capteur" className="ml-4 w-4 lg:w-6 inline-block" />;
      default:
        return null;
    }
  };

  const getAgencyName = (userId) => {
    for (const agencyId in agencyUsers) {
      const users = agencyUsers[agencyId];
      const matchedUser = users.find(user => user.id === userId);
      if (matchedUser) {
        const agency = agencies.find(agency => agency.id === agencyId);
        return agency ? agency.nom : "";
      }
    }
    return "";
  };
  

  return (
    <div>
      <div className="flex justify-center sm:justify-start mb-4">
        <select
          value={selectedAgency}
          onChange={handleAgencyChange}
          className="text-sm md:text-base lg:text-xl 3xl:text-2xl border-4 border-loginBordure rounded-3xl p-4 text-center"
        >
          <option value="">Toutes les agences</option>
          {agencies.map(agency => (
            <option key={agency.id} value={agency.id}>{agency.nom}</option>
          ))}
        </select>
      </div>
      <table className="text-xs sm:text-base xl:text-xl w-full 3xl:text-5xl">
        <thead>
          <tr>
            <th colSpan="7" className="bg-loginBordure text-white px-4 py-2 rounded-tl-3xl rounded-tr-3xl border-t-0 border-loginBordure text-center">Utilisateurs</th>
          </tr>
          <tr className="text-center">
            <th className="px-4 py-2 hidden sm:table-cell">Nom</th>
            <th className="px-4 py-2 hidden sm:table-cell">Prénom</th>
            <th className="px-4 py-2 hidden sm:table-cell">Agence</th>
            <th className="px-4 py-2">Email</th>
            <th className="px-4 py-2">Niveau d'accès</th>
          </tr>
        </thead>
        <tbody className="contents h-fit-content overflow-y-scroll text-start sm:overflow-y-hidden">
          {filteredUsers.map(currentUser => (
            <tr key={currentUser.id} className="text-left cursor-default text-loginBordure even:bg-gray-200">
              <td className="px-4 py-2 hidden sm:table-cell">{currentUser.nom}</td>
              <td className="px-4 py-2 hidden sm:table-cell">{currentUser.prenom}</td>
              <td className="px-4 py-2 hidden sm:table-cell">{getAgencyName(currentUser.id)}</td>
              <td className="px-0 py-0 pl-4 sm:pl-0 sm:px-4 sm:py-2">{currentUser.mail}</td>
              <td className="px-0 py-0 sm:px-4 sm:py-2 text-center">
                {getAccessLevelIcon(currentUser.type)}
              </td>
              <td className="px-0 py-0 sm:px-4 sm:py-2 ">
                <FaEdit
                  onClick={() => {
                    setSelectedUserId(currentUser.id);
                    setSelectedUserEmail(currentUser.mail);
                  }}
                  title="Modifier les informations de l'utilisateur"
                  className="cursor-pointer ml-2 m-2 hover:text-blue-900 transition-all duration-200 ease-in-out"
                />
              </td>
              <td className="px-4 py-2">
              <FaTrashAlt
  onClick={() => {
    setUserToDelete({ mail: currentUser.mail, id: currentUser.id })
    toggleSuppModal()
    // handleConfirmation(currentUser.id, currentUser.mail); 
  }}
  title="Supprimer l'utilisateur"
  className="cursor-pointer m-2 hover:text-red-900 transition-all duration-200 ease-in-out"
/>

                <Modal
                  isShowing={isSuppModalShowing}
                  hide={toggleSuppModal}
                  title="Confirmation de suppression"
                >
                  <p className="text-center text-sm 3xl:text-3xl">Êtes-vous sûr de vouloir supprimer cet utilisateur ?</p>
                  <div className="flex justify-center gap-8 mt-8">
                    <button className="bg-red-600 hover:bg-red-500 text-white 3xl:text-3xl text-base font-medium border-none rounded-md px-2 py-1 w-40 cursor-pointer" onClick={() => handleConfirmation(userToDelete.id, userToDelete.mail)}>
                      Supprimer
                    </button>
                    <button className="bg-orangeButton hover:bg-orange-400 text-white 3xl:text-3xl text-base font-medium border-none rounded-md px-2 py-1 w-40 cursor-pointer" onClick={toggleSuppModal}>
                      Annuler
                    </button>
                  </div>
                </Modal>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-5">
        <span className="text-xs sm:text-base xl:text-xl 3xl:text-3xl">
          {!isAdmin && (
            <span className="flex items-center">
              <FaUserShield className="mr-1 text-red-500" /> SuperAdmin
            </span>
          )}
          <span className="flex items-center">
            <FaUserCog className="mr-1 text-yellow-500" /> Admin
          </span>
          <span className="flex items-center">
            <FaUserTie className="mr-1 text-green-500" /> Chef d'agence
          </span>
          <span className="flex items-center">
            <FaUserTie className="mr-1 text-blue-500" /> Chef d'atelier
          </span>
          {!isAdmin && (
            <span className="flex items-center">
              <img src={capteur} alt="Capteur" className="mr-1" /> Capteur
            </span>
          )}
        </span>
      </div>
    </div>
  );
}
