import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit, FaTimes } from "react-icons/fa";
import { API_URL } from "../constants";
import { UserTypes } from "../constants";

const MachineAgencyAddress = ({ address, agenceId, token, agence }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [editMode, setEditMode] = useState(false);
  const [newAddress, setNewAddress] = useState(address);
  const [adresseSuggestions, setAdresseSuggestions] = useState([]);
  const [adresseQuery, setAdresseQuery] = useState(address); // Initialiser l'état du champ d'édition avec l'adresse existante
  const [showClearIcon, setShowClearIcon] = useState(false); // Nouvel état pour gérer l'affichage de l'icône de croix
  const [suggestionActivated, setSuggestionActivated] = useState(false); // Nouvel état pour gérer l'état de la suggestion activée
  const userType = user.type;

  const fetchAdresseSuggestions = async (query) => {
    try {
      const encodedText = encodeURIComponent(query);
      const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${encodedText}`);
      setAdresseSuggestions(response.data.features);
    } catch (error) {
      console.log("Erreur lors de la récupération des suggestions d'adresse :", error.message);
    }
  };

  useEffect(() => {
    if (adresseQuery.trim() !== "" && editMode) {
      fetchAdresseSuggestions(adresseQuery);
    } else {
      setAdresseSuggestions([]);
    }
  }, [adresseQuery, editMode]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setAdresseQuery(value);
    setShowClearIcon(value !== "");
    setSuggestionActivated(false); // Réinitialiser l'état de la suggestion activée lorsque l'utilisateur remodifie l'input
  };

  const handleSuggestionClick = (suggestionLabel) => {
    setAdresseQuery(suggestionLabel); // Mettre à jour l'état du champ d'édition avec la suggestion cliquée
    setSuggestionActivated(true); // Activer l'état de la suggestion après avoir cliqué sur une suggestion
  };

  const handleClearInput = () => {
    setAdresseQuery("");
    setShowClearIcon(false);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setAdresseQuery(address); // Réinitialiser l'état du champ d'édition avec l'adresse existante en cas d'annulation
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.patch(
        `${API_URL}/agences/${agenceId}`,
        {
          adresse: adresseQuery, // Utiliser l'état du champ d'édition pour la mise à jour
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data) {
        setEditMode(false);
        setNewAddress(adresseQuery); // Mettre à jour l'adresse avec la nouvelle valeur
        window.location.reload();
      } else {
        console.error("Erreur lors de la mise à jour de l'adresse de l'agence");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'adresse de l'agence", error);
    }
  };

  return (
    <div>
      {editMode ? (
        // Champ d'édition pour l'adresse en mode édition
        <div className="mb-4">
          <div>
          <input
            type="text"
            value={agence.adresse}
            onChange={handleInputChange}
            className="border-2 border-gray-300 rounded-md p-2 m-2 w-full"
          />
          {adresseSuggestions.length > 0 && editMode && !suggestionActivated && (
        <div className="mt-4">
          {adresseSuggestions.map((suggestion) => (
            <div
              key={suggestion.properties.id}
              className="cursor-pointer bg-gray-200 hover:bg-gray-400 rounded-md p-2 mb-1"
              onClick={() => handleSuggestionClick(suggestion.properties.label)}
            >
              {suggestion.properties.label}
            </div>
          ))}
        </div>
      )}
      </div>
          {showClearIcon && <FaTimes className="cursor-pointer text-black mr-2" onClick={handleClearInput} />}
          <div className="flex mt-4">
          <button onClick={handleSaveClick} className="bg-loginBordure hover:bg-blue-900 text-white rounded-md px-4 py-2 mr-2 text-base">
            Modifier
          </button>
          <button
            onClick={handleCancelClick}
            className="bg-red-500 hover:bg-red-700 text-white rounded-md px-4 py-2  text-base"
          >
            Annuler
          </button>
          </div>
        </div>
      ) : (
        // Affichage de l'adresse en mode non édition
        <div>
          <span className="inline-flex text-decoration-none font-light mb-4 mt-4">
            <div className="inline-flex font-bold underline mr-2 text-base">Adresse:</div>
            <div className="text-sm md:text-base lg:text-xl 3xl:text-2xl">{agence.adresse}</div>
            {userType === UserTypes.SUPER || userType === UserTypes.ADMIN ? (
              <FaEdit className="cursor-pointer ml-4 hover:text-blue-500 transition-all duration-200 ease-in-out" onClick={handleEditClick} />
            ) : null}
          </span>
        </div>
      )}

      {/* Afficher les suggestions d'adresse */}
      
    </div>
  );
};

export default MachineAgencyAddress;
