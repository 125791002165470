import React, { useState, useEffect } from "react";
import { API_URL } from "../constants";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

export default function ChangeTypeForm({ id, rafraichir, token }) {
  const [newuser, setNewuser] = useState({ type: "" });
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${API_URL}/users/${id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });

        const userData = response.data;
        setNewuser({ type: userData.type || "" });
        setTwoFactorEnabled(userData.twoFactorEnabled || false);
      } catch (error) {
        if (error.response?.status === 401) {
          alert("ERREUR : Votre session a expiré. Veuillez vous reconnecter.");
          localStorage.clear();
          navigate("/login");
        } else {
          console.error("Erreur récupération utilisateur:", error.message);
        }
      }
    };

    fetchUser();
  }, [id, token, navigate]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(""), 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);


  const handleChange = (event) => {
    setNewuser({ ...newuser, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await axios.patch(`${API_URL}/users/${id}`, { type: newuser.type }, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      setMessage("Niveau d'accès modifié avec succès !");
      rafraichir();
      window.location.reload();
    } catch (error) {
      console.error("Erreur mise à jour type utilisateur:", error.message);
      setMessage("Échec de la mise à jour.");
    }
  };

  const toggleTwoFactorAuth = async () => {
    const endpoint = twoFactorEnabled
      ? `/users/${id}/disable-2fa`
      : `/users/${id}/enable-2fa`;

    try {
      await axios.post(`${API_URL}${endpoint}`, {}, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      setTwoFactorEnabled(!twoFactorEnabled);
      setMessage(`Double authentification ${!twoFactorEnabled ? "activée" : "désactivée"} !`);
    } catch (error) {
      console.error("Erreur activation/désactivation 2FA:", error.message);
      setMessage("Erreur lors de la modification du 2FA.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col justify-center text-center">
      <label className="flex flex-col">
        <select
          className="text-sm md:text-base lg:text-xl border-4 border-loginBordure rounded-3xl p-4 text-center mt-4 mb-4"
          onChange={handleChange}
          value={newuser.type}
          name="type"
        >
          <option value="">Nouveau niveau d'accès</option>
          <option value="0">SuperAdmin</option>
          <option value="1">Admin</option>
          <option value="2">Chef d'agence</option>
          <option value="3">Chef d'atelier</option>
          <option value="4">Capteur</option>
        </select>
      </label>

      <input type="submit" value="Valider" className="bg-loginBordure hover:bg-blue-900 text-white font-semibold hover:text-white hover:font-bold text-sm 3xl:text-xl border-none rounded-full p-2 w-11/12 lg:w-60 mr-auto ml-auto cursor-pointer"/>

      <label className="flex items-center justify-center mt-6 space-x-3 text-lg">
        <input type="checkbox" checked={twoFactorEnabled} onChange={toggleTwoFactorAuth} className="w-5 h-5" />
        <span>{twoFactorEnabled ? "Désactiver" : "Activer"} la double authentification (2FA)</span>
      </label>

      {message && <p className="bg-green-500 rounded-lg text-white mx-auto p-2 text-center my-4 text-lg">{message}</p>}
    </form>
  );
}
