import React, { useState, useEffect } from "react";
import { API_URL } from "../constants";
import axios from "axios";
import { FaEdit , FaTrash } from "react-icons/fa";
import MachineAgencyAddress from "./MachineAgencyAddress";
import MachineAgencyChiefs from "./MachineAgencyChiefs";
import MachineWifiInfo from "./MachineWifiInfo";
import { UserTypes } from "../constants";

const MachineAgency = ({ token }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [agences, setAgences] = useState([]);
  const [agenceId, setAgenceId] = useState(null);
  const [agence, setAgence] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [newAgenceName, setNewAgenceName] = useState("");
  const userType = user.type;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  useEffect(() => {
    // Effectuer la requête pour récupérer toutes les agences
    axios.get(`${API_URL}/agences`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      setAgences(response.data.data);
    })
    .catch(error => {
      console.error("Erreur lors de la récupération des agences :", error.message);
    });
  }, [token]);

  useEffect(() => {
    const fetchAgenceData = async () => {
      try {
        const response = await axios.get(`${API_URL}/agences/${agenceId}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setAgence(response.data);
        setNewAgenceName(response.data.nom);
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'agence :", error.message);
      }
    };

    if (agenceId) {
      fetchAgenceData();
    } else {
      setAgence(null);
      setNewAgenceName("");
    }
  }, [agenceId, token]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleInputChange = (event) => {
    setNewAgenceName(event.target.value);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setNewAgenceName(agence ? agence.nom : ""); // Réinitialiser le nouveau nom avec le nom actuel de l'agence en cas d'annulation
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.patch(
        `${API_URL}/agences/${agenceId}`,
        {
          nom: newAgenceName,
          
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data) {
        // Mettre à jour le mode d'édition et l'agence avec les nouvelles données
        setEditMode(false);
        setAgence(response.data.data);
        window.location.reload();
        // Si l'identifiant de l'agence est "none", cela signifie que "Aucune agence associée" a été sélectionnée
        // Dans ce cas, mettez à jour l'identifiant de l'agence sélectionnée dans le composant parent
      } else {
        console.error("Erreur lors de la mise à jour du nom de l'agence");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du nom de l'agence", error);
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`${API_URL}/agences/${agenceId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Mettre à jour la liste des agences après suppression
      const updatedAgences = agences.filter((agence) => agence.id !== agenceId);
      setAgences(updatedAgences);

      // Fermer le modal et réinitialiser l'ID de l'agence sélectionnée
      setShowDeleteModal(false);
      setAgenceId(null);
      window.location.reload();
    } catch (error) {
      console.error("Erreur lors de la suppression de l'agence :", error.message);
    }
  };

  return (
    <div>
      <h4 className="border-solid border-4 border-t-0 border-b-0 border-r-0 border-l-0 border-loginBordure pt-4 pb-4 text-xl 3xl:text-3xl text-underline">
        {agence ? (
          <>
            <div className="flex justify-center">
              {editMode ? (
                // Champ d'édition pour le nom de l'agence en mode édition
                <div className="inline items-center mb-4">
                  <input
                    type="text"
                    value={newAgenceName}
                    onChange={handleInputChange}
                    className="border-2 border-gray-300 rounded-md p-2 mr-2 mb-4 w-44 sm:w-56"
                  />
                  <button
                    onClick={handleSaveClick}
                    className="bg-loginBordure hover:bg-blue-900 text-white rounded-md px-4 py-2 text-base"
                  >
                    Valider
                  </button>
                  <button
                    onClick={handleCancelClick}
                    className="bg-red-500 hover:bg-red-700 text-white rounded-md px-4 py-2 ml-2 text-base"
                  >
                    Annuler
                  </button>
                </div>
              ) : (
                // Affichage du nom de l'agence en mode non édition
                <div className="inline-flex items-center text-2xl lg:text-4xl 3xl:text-5xl mb-4 font-bold text-loginBordure text-center">
                  {newAgenceName}{" "}
                  {userType === UserTypes.SUPER || userType === UserTypes.ADMIN ? (
                    <FaEdit className="cursor-pointer ml-4 hover:text-blue-500 transition-all duration-200 ease-in-out" onClick={handleEditClick} />
                  ) : null}
                </div>
              )}
            </div>
            <div className="mb-4">
              <MachineAgencyAddress address={agence.adresse} agenceId={agenceId} agence={agence} token={token} />
            </div>
            <div className="mb-4">
              <MachineAgencyChiefs agence={agence} token={token} />
            </div>
            {userType === UserTypes.SUPER ? (
        <button
          className="text-orangeButton mt-2 flex items-center hover:text-orange-600 mb-4 text-xs sm:text-base"
          onClick={handleDeleteClick}
        >
          <FaTrash className="mr-1" />
          Supprimer l'agence
        </button>
      ) : null}
          </>
        ) : (
          <p></p>
        )}

        {/* Ajouter la liste déroulante des agences */}
        <div>
          <label className="text-xs sm:text-xl block font-bold mb-2 text-loginBordure" htmlFor="agenceSelect">
            Choisir une agence :
          </label>
          <select
            id="agenceSelect"
            value={agenceId || ""}
            onChange={(e) => setAgenceId(e.target.value)}
            className="border-2 border-gray-300 rounded-md p-2 w-44 sm:w-64 lg:w-full text-xs md:text-base lg:text-xl 3xl:text-2xl"
            >
              <option value="">Sélectionner une agence</option>
              {agences.map((agence) => (
                <option key={agence.id} value={agence.id}>
                  {agence.nom}
                </option>
              ))}
            </select>
          </div>
        </h4>
        {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center z-10 bg-black bg-opacity-50">
        <div className="bg-white p-4 rounded-md shadow-lg">
          <p className="text-base sm:text-lg text-center font-semibold mb-2">
          Êtes-vous sûr de vouloir supprimer cette agence : 
          </p>
          <p className="text-xl text-center font-bold mb-4">{agence.nom}</p>
          <div className="flex justify-center">
            <button
              className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded-md text-white font-semibold mr-5"
              onClick={() => setShowDeleteModal(false)}
            >
              Annuler
            </button>
            <button
              className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-md text-white font-semibold"
              onClick={handleConfirmDelete}
            >
              Confirmer
            </button>
          </div>
        </div>
      </div>
      )}
      </div>
    );
  };
  
  export default MachineAgency;
  