import React, { useState, useEffect } from "react";
import { API_URL } from "../constants";
import axios from "axios";
import { FaEdit , FaTrash } from "react-icons/fa";
import MachineModel from "./MachineModel";
import MachineBrand from "./MachineBrand";
import { UserTypes } from "../constants";
import { useNavigate } from "react-router-dom";

const MachineType = ({ token }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [machineTypes, setMachineTypes] = useState([]);
  const [typeId, setTypeId] = useState("");
  const [machineType, setMachineType] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const [newTypeName, setNewTypeName] = useState("");
  const userType = user.type;
  const [isCreatingMaintenance, setIsCreatingMaintenance] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCreate, setErrorCreate] = useState("");
  const [allMaintenances, setAllMaintenances] = useState([]);
  const [matchingMaintenances, setMatchingMaintenances] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

const toggleCreatingMaintenanceModal = () => {
  setIsCreatingMaintenance(!isCreatingMaintenance);
};

const handleCancelClick2 = () => {
  setIsCreatingMaintenance(false);
    setMaintenanceData({
      nom: "",
      description: "",
      typeMachine: {"id" : `${typeId}`},
      heures: 0,
      freq_intervention: 0,
      etat: "A_JOUR"
    });
};

useEffect(() => {
  // Obtenez toutes les maintenances au chargement du composant
  axios
    .get(`${API_URL}/maintenances`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const allMaintenances = response.data.data;
      setAllMaintenances(allMaintenances);
    })
    .catch((error) => {
      console.error(error);
    });
}, [token]);

useEffect(() => {
  // Filtrez la liste complète des maintenances en fonction du terme de recherche
  if (searchTerm.trim() !== "") {
    const filteredMaintenances = allMaintenances.filter((maintenance) =>
      maintenance.nom.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Utilisez un ensemble (Set) pour stocker les noms uniques
    const uniqueNames = new Set(filteredMaintenances.map((maintenance) => maintenance.nom));

    // Convertissez l'ensemble en tableau pour l'affichage
    setMatchingMaintenances([...uniqueNames]);
  } else {
    // Réinitialisez la liste si le champ de recherche est vide
    setMatchingMaintenances([]);
  }
}, [searchTerm, allMaintenances]);


  const [maintenanceData, setMaintenanceData] = useState({
    nom: "",
    description: "",
    typeMachine: {"id" : `${typeId}`},
    heures: 0,
    freq_intervention: 0,
    etat: "A_JOUR"

  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newValue = name === "heures" || name === "freq_intervention" ? parseInt(value) : value;

    if (name === "nom") {
      setSearchTerm(newValue);
    }

    setMaintenanceData({ ...maintenanceData, [name]: newValue });
  };

  const handleCreateMaintenance = async () => {
    try {
      await axios.post(
        `${API_URL}/maintenances`,
        {
          nom: maintenanceData.nom,
          description: maintenanceData.description,
          typeMachine: {"id" : `${typeId}`},
          heures: parseInt(maintenanceData.freq_intervention),
          freq_intervention: parseInt(maintenanceData.freq_intervention),
          etat: "A_JOUR"
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      setIsCreatingMaintenance(false);
      setMaintenanceData({
        nom: "",
        description: "",
        typeMachine: {"id" : `${typeId}`},
        heures: 0,
        freq_intervention: 0,
        etat: "A_JOUR"
      });
  
    } catch (error) {
      console.error("Erreur lors de la création de la maintenance", error);
      // Vous pouvez afficher l'erreur du serveur ici, car c'est une erreur HTTP 409 (Conflict)
      if (error.response && error.response.data && error.response.data.message) {
        setErrorCreate(error.response.data.message);
        setTimeout(() => {
          setErrorCreate("");
        }, 3000);
      } else {
        setErrorCreate("Une erreur s'est produite lors de la création de la maintenance.");
        setTimeout(() => {
          setErrorCreate("");
        }, 3000);
      }
    }
  };
  
  useEffect(() => {
    // Fetch the list of machine types
    axios
      .get(`${API_URL}/typesmachine`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setMachineTypes(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching machine types:", error.message);
      });
  }, [token]);

  useEffect(() => {
      const fetchMachineTypeData = async () => {
        try {
          const response = await axios.get(`${API_URL}/typesmachine/${typeId}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          setMachineType(response.data);
          setNewTypeName(response.data.nom); // Pré-remplir le nouveau nom avec le nom actuel du type
        } catch (error) {
          console.error("Erreur lors de la récupération des informations du type de machine :", error.message);
        }
      };
      fetchMachineTypeData();
  }, [typeId, token]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setNewTypeName(machineType.nom); // Réinitialiser le nouveau nom avec le nom actuel du type en cas d'annulation
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.patch(
        `${API_URL}/typesmachine/${typeId}`,
        {
          nom: newTypeName, // Utiliser le nouveau nom dans la requête de mise à jour
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200 && response.data) {
        setEditMode(false);
        // Mettre à jour le nom du type affiché après la mise à jour réussie
        setMachineType((prevState) => ({ ...prevState, nom: newTypeName }));
        // Mettre à jour le nom du type dans la liste des machineTypes
        setMachineTypes((prevMachineTypes) =>
          prevMachineTypes.map((machineType) =>
            machineType.id === typeId ? { ...machineType, nom: newTypeName } : machineType
          )
        );
        window.location.reload();
      } else {
        console.error("Erreur lors de la mise à jour du nom du type de machine");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du nom du type de machine", error);
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`${API_URL}/typesmachine/${typeId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Mettre à jour la liste des types de machine après suppression
      const updatedMachineTypes = machineTypes.filter(
        (type) => type.id !== typeId
      );
      setMachineTypes(updatedMachineTypes);

      // Fermer le modal et réinitialiser l'ID du type de machine sélectionné
      setShowDeleteModal(false);
      setTypeId("");
      window.location.reload(); // À optimiser pour éviter le rechargement complet
    } catch (error) {
      console.error("Erreur lors de la suppression du type de machine :", error.message);
      // Mettez à jour le message d'erreur en utilisant le message d'erreur retourné par le backend
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Une erreur s'est produite lors de la suppression du type de machine.");
      }
    }
  };

  return (
    <div>
      <h4 className="border-solid border-4 border-b-0 border-t-0 border-r-0 border-l-0 border-loginBordure pt-4 pb-4 text-xl 3xl:text-3xl text-underline">
        {typeId ? (
          <>
            <div className="flex justify-center">
              {editMode ? (
                <div className="inline items-center mb-4">
                  <input
                    type="text"
                    value={newTypeName}
                    onChange={(e) => setNewTypeName(e.target.value)}
                    className="border-2 border-gray-300 rounded-md p-2 mr-2 mb-4 w-44 sm:w-56"
                  />
                  <button
                    onClick={handleSaveClick}
                    className="bg-loginBordure hover:bg-blue-900 text-white rounded-md px-4 py-2 text-base"
                  >
                    Valider
                  </button>
                  <button
                    onClick={handleCancelClick}
                    className="bg-red-500 hover:bg-red-700 text-white rounded-md px-4 py-2 ml-2 text-base"
                  >
                    Annuler
                  </button>
                </div>
              ) : (
                <>
                  <div className="inline-flex items-center text-2xl lg:text-4xl 3xl:text-5xl mb-8 font-bold text-loginBordure text-center">
                    {machineType.nom}
                    {userType === UserTypes.SUPER || userType === UserTypes.ADMIN ? (
                      <FaEdit className="cursor-pointer ml-4 hover:text-blue-500 transition-all duration-200 ease-in-out" onClick={handleEditClick} />
                    ) : null}
                  </div>
                </>
              )}
            </div>
            <MachineModel typeId={typeId} token={token} />
            <MachineBrand typeId={typeId} token={token} />
            
            {userType === UserTypes.SUPER ? (
          <button
            className="text-orangeButton mt-2 flex items-center hover:text-orange-600 mb-4 mt-4 text-xs sm:text-base"
            onClick={handleDeleteClick}
          >
            <FaTrash className="mr-1" />
            Supprimer ce type de machine
          </button>
        ) : null}
          </>
        ) : (
          <p></p>
        )} <div>
        {!isCreatingMaintenance && typeId && (
        <button
        className="bg-loginBordure hover:bg-blue-900 text-white font-semibold hover:text-white hover:font-bold text-xs mt-2 sm:text-base rounded-2xl py-2 px-2 sm:py-4 sm:px-6 mr-4 cursor-pointer 3xl:text-4xl 3xl:px-10 3xl:py-10"
        onClick={toggleCreatingMaintenanceModal} // Utilisez la nouvelle fonction pour ouvrir/fermer la modal
      >
        Créer une maintenance pour ce type
      </button>
      
      )}</div>
      {isCreatingMaintenance && typeId && (
        <div className="flex flex-col space-y-6 items-center mt-5 px-4 sm:px-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">
            {/* Nom de la maintenance */}
            <div className="flex flex-col">
              <label htmlFor="nom" className="text-sm font-medium text-gray-700 mb-2">Nom de la maintenance</label>
              <input
                type="text"
                name="nom"
                value={maintenanceData.nom}
                onChange={handleInputChange}
                placeholder="Nom de la maintenance"
                className="border border-gray-300 rounded-lg px-4 py-2 text-base sm:text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              />
            </div>

            {/* Description de la maintenance */}
            <div className="flex flex-col">
              <label htmlFor="description" className="text-sm font-medium text-gray-700 mb-2">Description de la maintenance</label>
              <input
                type="text"
                name="description"
                value={maintenanceData.description}
                onChange={handleInputChange}
                placeholder="Description de la maintenance"
                className="border border-gray-300 rounded-lg px-4 py-2 text-base sm:text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">

            {/* Fréquence d'intervention */}
            <div className="flex flex-col mb-6">
              <label htmlFor="freq_intervention" className="text-sm font-medium text-gray-700 mb-2">Fréquence d'intervention (en heures)</label>
              <input
                type="number"
                id="freq_intervention"
                name="freq_intervention"
                value={maintenanceData.freq_intervention}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-lg px-4 py-2 text-base sm:text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              />
            </div>
          </div>

          {/* Liste de suggestions */}
          {matchingMaintenances.length > 0 && (
            <ul className="suggestions-list mx-auto bg-white border rounded w-full mt-4">
              {matchingMaintenances.map((maintenanceName) => (
                <li
                  key={maintenanceName}
                  onClick={() => {
                    const matchingMaintenance = allMaintenances.find(
                      (maintenance) => maintenance.nom === maintenanceName
                    );
                    if (matchingMaintenance) {
                      setMaintenanceData({
                        ...maintenanceData,
                        description: matchingMaintenance.description,
                        heures: matchingMaintenance.heures,
                        freq_intervention: matchingMaintenance.freq_intervention,
                        nom: matchingMaintenance.nom,
                      });
                    }
                    setMatchingMaintenances([]);
                    setSearchTerm("");
                  }}
                  className="cursor-pointer px-4 py-2 hover:bg-gray-200"
                >
                  {maintenanceName}
                </li>
              ))}
            </ul>
          )}

          {/* Boutons de validation et annulation */}
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mt-4 w-full justify-center">
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white text-base sm:text-xl px-6 py-3 rounded-md focus:outline-none"
              onClick={handleCreateMaintenance}
            >
              Valider
            </button>
            <button
              onClick={handleCancelClick2}
              className="bg-red-500 hover:bg-red-700 text-white text-base sm:text-xl px-6 py-3 rounded-md focus:outline-none"
            >
              Annuler
            </button>
          </div>

          {/* Message d'erreur */}
          {errorCreate && (
            <p className="bg-red-500 text-white text-lg mt-4 p-4 rounded-md w-full text-center">
              {errorCreate}
            </p>
          )}
        </div>
      )}

        {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center z-10 bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-md shadow-lg">
            <p className="text-base sm:text-lg text-center font-semibold mb-2">
              Êtes-vous sûr de vouloir supprimer ce type de machine :
            </p>
            <p className="text-xl text-center font-bold mb-4">{machineType.nom}</p>
            {errorMessage && (
              <p className="text-red-500 text-center mb-4">{errorMessage}</p>
            )}
            <div className="flex justify-center">
              <button
                className="bg-red-500 hover:bg-red-600 px-4 py-2 rounded-md text-white font-semibold mr-5"
                onClick={handleCloseModal}
              >
                Annuler
              </button>
              <button
                className="bg-green-500 hover:bg-green-600 px-4 py-2 rounded-md text-white font-semibold"
                onClick={handleConfirmDelete}
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
      )}
      </h4>

      {/* Add the dropdown select for machine types */}
      <div className="-mt-2">
        <label className="text-xs sm:text-xl block font-bold mb-2 text-loginBordure">
          Choisir un type de machine :
        </label>
        <select
          id="machineTypeSelect"
          value={typeId || ""}
          onChange={(e) => setTypeId(e.target.value)}
          className="border-2 border-gray-300 rounded-md p-2 w-44 sm:w-64 lg:w-full text-xs md:text-base lg:text-xl 3xl:text-2xl"
        >
          <option value="">Sélectionner un type de machine</option>
          {machineTypes.map((machineType) => (
            <option key={machineType.id} value={machineType.id}>
              {machineType.nom}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default MachineType;
