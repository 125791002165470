import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { AiOutlineSetting, AiOutlineLeft } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router-dom";
import useModal from "../Modal/useModal";
import logoImage from "./logo.png";
import { UserTypes } from "../constants";

export default function Header(props) {
  const storedUser = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null; // Assurer que user peut être null avant connexion
  const user = props.user || storedUser; // Prioriser props.user si défini
  const location = useLocation();
  const navigate = useNavigate();
  const { isShowing: isParametreFormShowed, toggle: toggleParametreForm } = useModal();
  
  // Vérifie que `user` n'est pas null avant d'accéder à `user.type`
  const userType = user?.type;

  const handleDeconnexion = () => {
    localStorage.removeItem("user"); // Supprimer l'utilisateur stocké
    navigate("/login");
  };

  const logout = () => {
    handleDeconnexion();
  };

  const isSettingIconVisible =
    user &&
    (userType === UserTypes.SUPER ||
      userType === UserTypes.ADMIN ||
      userType === UserTypes.CHEFAG);

  const [isFirstPage, setIsFirstPage] = useState(false);

  useEffect(() => {
    setIsFirstPage(location.pathname === "/machinesList");
  }, [location.pathname]);

  return (
    <div className="bg-loginBordure flex items-center flex-col justify-center sm:flex-row sm:justify-evenly text-white w-full">
      <div className="flex flex-row items-center mb-1 mt-1">
        {user && location.pathname !== "/" && (
          <IconContext.Provider
            value={{
              className: "image",
              size: "2em",
              style: {
                marginTop: "auto",
                marginBottom: "auto",
              },
            }}
          >
            {isFirstPage ? (
              <></>
            ) : (
              <AiOutlineLeft
                className="cursor-pointer hover:text-black text-2xl 3xl:text-5xl mr-5"
                onClick={() => navigate(-1)}
              />
            )}
          </IconContext.Provider>
        )}
        {/* Wrap the logo image with an anchor tag */}
        {location.pathname === "/login" ? (
          <img
            src={logoImage}
            alt="Logo"
            className="h-16 sm:h-20 md:h-28 3xl:h-40 cursor-default"
          />
        ) : (
          <a href="/machinesList">
            <img
              src={logoImage}
              alt="Logo"
              className="h-16 sm:h-20 md:h-28 3xl:h-40"
            />
          </a>
        )}
      </div>
      {user ? (
        <div className="flex flex-row items-center mb-5 sm:mb-0">
          <button
            className="bg-orangeButton hover:bg-orange-600 text-white hover:text-white text-2xl rounded-2xl py-4 px-6 mr-4 cursor-pointer 3xl:text-4xl 3xl:px-10 3xl:py-10"
            onClick={logout}
          >
            Déconnexion
          </button>
          {isSettingIconVisible && (
            <IconContext.Provider
              value={{
                className: "image",
                size: "2em",
                style: {
                  marginTop: "auto",
                  marginBottom: "auto",
                },
              }}
            >
              <AiOutlineSetting
                onClick={() => {
                  navigate("/admin");
                }}
                className="cursor-pointer hover:text-black text-2xl 3xl:text-5xl"
              />
            </IconContext.Provider>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
