import React, { useState } from "react";
import { FaEdit, FaTimes } from "react-icons/fa";
import { API_URL } from "../constants";
import { UserTypes } from "../constants";

const MachineTime = ({ time, machineId, token }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [editMode, setEditMode] = useState(false);
  const [newTime, setNewTime] = useState(time);
  const [initialTime, setInitialTime] = useState(time);
  const userType = user.type;

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleInputChange = (event) => {
    setNewTime(event.target.value);
  };

  const handleSaveClick = async () => {
    try {
      const response = await fetch(`${API_URL}/machines/${machineId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          heuresUtilisation: parseInt(newTime)
        }),
      });

      if (response.ok) {
        setEditMode(false);
      } else {
        console.error("Erreur lors de la mise à jour du temps d'utilisation de la machine");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du temps d'utilisation de la machine", error);
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setNewTime(initialTime);
  };

  return (
    <div>
      <h4 className="pt-4 pb-4 text-xl 3xl:text-3xl text-underline">
        {editMode ? (
          // Afficher le champ d'édition si editMode est true
          <div className="inline items-center mt-4">
            <input
              type="number"
              value={newTime}
              onChange={handleInputChange}
              className="border-2 border-gray-300 rounded-md p-2 mr-2 mb-4"
            />
            <button
              onClick={handleSaveClick}
              className="bg-loginBordure hover:bg-blue-900 text-white rounded-md px-4 py-2"
            >
              Modifier
            </button>
            <button
              onClick={handleCancelClick}
              className="bg-red-500 hover:bg-red-700 text-white rounded-md px-4 py-2 ml-2"
            >
              Annuler
            </button>
          </div>
        ) : (
          // Sinon, afficher le temps d'utilisation avec l'icône d'édition (uniquement pour les utilisateurs de type "SUPER" ou "ADMIN")
          <div>
            <span className="inline-flex text-decoration-none font-light">
              <div className="inline-flex font-bold underline mr-2 text-xs lg:text-xl 3xl:text-3xl">Durée totale de fonctionnement :</div>
              <span className="text-xs lg:text-xl 3xl:text-3xl">{newTime}h</span>
              {userType === UserTypes.SUPER || userType === UserTypes.ADMIN ? (
                <FaEdit className="cursor-pointer ml-4 hover:text-blue-500 transition-all duration-200 ease-in-out" onClick={handleEditClick} />
              ) : null}
            </span>
          </div>
        )}
      </h4>
    </div>
  );
};

export default MachineTime;
