import React, { useState, useEffect } from "react";
import { API_URL } from "../constants";
import axios from "axios";
import { FaEdit, FaTimes } from "react-icons/fa";
import { UserTypes } from "../constants";

const MachineAssociatedAgency = ({ agenceId, token, machineId, onAgenceChange }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [agence, setAgence] = useState(null);
  const [name, setName] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [allAgences, setAllAgences] = useState([]);
  const [selectedAgence, setSelectedAgence] = useState("");
  const [displayedAgenceName, setDisplayedAgenceName] = useState("");
  const userType = user.type;

  // Fonction pour enregistrer l'identifiant de l'agence sélectionnée dans le "localStorage"
  const saveSelectedAgenceToLocalStorage = (agenceId) => {
    localStorage.setItem("selectedAgenceId", agenceId);
  };

  useEffect(() => {
    const fetchAgenceData = async () => {
      try {
        const response = await axios.get(`${API_URL}/agences/${agenceId.id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setAgence(response.data);
        console.log(response.data)
        setName(response.data.nom);

        setSelectedAgence(agenceId); // Mettre à jour l'agence sélectionnée au chargement initial
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'agence :", error.message);
      }
    };

    const fetchAllAgences = async () => {
      try {
        const response = await axios.get(`${API_URL}/agences`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        setAllAgences(response.data.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des agences :", error.message);
      }
    };

    fetchAgenceData();
    fetchAllAgences();
  }, [agenceId, token]);

  useEffect(() => {
    // Récupérer l'identifiant de l'agence dans le "localStorage" au chargement initial
    const storedAgenceId = localStorage.getItem("selectedAgenceId");

    // Mettre à jour l'agence sélectionnée avec l'identifiant récupéré du "localStorage"
    if (storedAgenceId) {
      setSelectedAgence(storedAgenceId);
    }

    // Mettre à jour l'agence affichée lorsque l'identifiant de l'agence change
    if (selectedAgence) {
      const agenceName = allAgences.find((agenceItem) => agenceItem.id === parseInt(selectedAgence))?.nom|| "";
      setDisplayedAgenceName(agenceName);
    }
  }, [selectedAgence, allAgences]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleAgenceChange = (event) => {
    const selectedAgenceId = event.target.value;
    setSelectedAgence(selectedAgenceId);
    saveSelectedAgenceToLocalStorage(selectedAgenceId); // Enregistrer l'identifiant de l'agence sélectionnée dans le "localStorage"
  };

  const handleSaveClick = async () => {
    try {
      const response = await axios.patch(
        `${API_URL}/machines/${machineId.toString()}`,
        {
          agence: {id:selectedAgence},// Utiliser l'agence sélectionnée plutôt que 'agenceId'
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data) {
        setEditMode(false);
        setAgence(allAgences.find((agenceItem) => agenceItem.id === parseInt(selectedAgence)) || null); // Mettre à jour les informations de l'agence affichée

        // Enregistrer l'identifiant de l'agence sélectionnée dans le "localStorage"
        saveSelectedAgenceToLocalStorage(selectedAgence);

        // Appeler la fonction onAgenceChange pour mettre à jour l'agence sélectionnée dans le composant parent
        onAgenceChange(selectedAgence);
      } else {
        console.error("Erreur lors de la mise à jour de l'agence associée à la machine");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'agence associée à la machine", error);
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setSelectedAgence(agenceId); // Réinitialiser l'agence sélectionnée avec l'agence d'origine
  };

  return (
    <h4 className="border-solid border-4 border-b-0 border-t-0 border-r-0 border-l-0 border-loginBordure pt-4 pb-4 text-xl 3xl:text-3xl text-underline">
      <div>
        {editMode ? (
          <div className="inline text-decoration-none font-light">
            <select
              value={selectedAgence.id}
              onChange={handleAgenceChange}
              className="border-2 border-gray-300 rounded-md p-2 mr-2 mb-4"
            >
              {allAgences.map((agenceItem) => (
                <option key={agenceItem.id} value={agenceItem.id}>
                  {agenceItem.nom}
                </option>
              ))}
            </select>
            <button
              onClick={() => {handleSaveClick(); window.history.back();}}
              className="bg-loginBordure hover:bg-blue-900 text-white rounded-md px-4 py-2"
            >
              Valider
            </button>
            <button
              onClick={handleCancelClick}
              className="bg-red-500 hover:bg-red-700 text-white rounded-md px-4 py-2 ml-2"
            >
              Annuler
            </button>
          </div>
        ) : (
          <span className="inline-flex text-decoration-none font-light">
            <div className="inline-flex font-bold underline mr-2 text-xs lg:text-xl 3xl:text-3xl">Agence associée :</div>
            <div className="text-xs lg:text-xl 3xl:text-3xl">{name|| "Aucune agence associée"}</div>
            {userType === UserTypes.SUPER || userType === UserTypes.ADMIN ? (
            <FaEdit className="cursor-pointer ml-4 hover:text-blue-500 transition-all duration-200 ease-in-out" onClick={handleEditClick} />
            ) : null}
          </span>
        )}
      </div>
    </h4>
  );
};

export default MachineAssociatedAgency;